import React from "react"
import styles from "./label.module.scss"
import PropTypes from "prop-types"

const Label = ({ label, mandatory, description }) => {
  return (
    <div className={styles.wrapper}>
      {label ? (
        <div className={styles.container}>
          {label ? (
            <div className={styles.label}>
              {label}{" "}
              {mandatory ? <span className={styles.star}> * </span> : ""}
            </div>
          ) : (
            ""
          )}
          {description ? (
            <div className={styles.description}> {description} </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

Label.propTypes = {
  label: PropTypes.string,
  mandatory: PropTypes.bool,
  description: PropTypes.string,
}

export default Label
