import React from "react"
import styles from "./error-message.module.scss"
import PropTypes from "prop-types"

const ErrorMessage = ({ errorMessage }) => {
  return <div className={styles.wrapper}>{errorMessage}</div>
}

ErrorMessage.propTypes = {
    errorMessage: PropTypes.string,
}

export default ErrorMessage
