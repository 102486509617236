import React, { useState } from "react"
import PropTypes from "prop-types"
import styles from "./modal-window.module.scss"
import ReactModal from "react-modal"
import { MdClose } from "react-icons/md"

function ModalWindow({ title, children, closeHandler, setModalOpen2 }) {
  ReactModal.setAppElement("body")

  const [modalOpen, setModalOpen] = useState(true)

  const getParentNode = () => {
    return document.querySelector("body")
  }

  const closeModal = () => {
    setModalOpen(false)
    if (closeHandler) {
      closeHandler()
    }
  }

  const handleAfterOpenFunc = () => {
    console.log("modal-window handleAfterOpenFunc fired.")
  }

  const handleAfterCloseFunc = () => {
    console.log("modal-window handleAfterCloseFunc fired.")
    setModalOpen(false)
    // setModalOpen2(false)
  }

  const handleRequestCloseFunc = () => {
    console.log("modal-window handleRequestCloseFunc fired.")
    setModalOpen(false)
    // setModalOpen2(false)
  }

  return (
    <ReactModal
      isOpen={
        modalOpen
        // false
        /* Boolean describing if the modal should be shown or not. */
      }
      onAfterOpen={
        handleAfterOpenFunc
        /* Function that will be run after the modal has opened. */
      }
      onAfterClose={
        handleAfterCloseFunc
        /* Function that will be run after the modal has closed. */
      }
      onRequestClose={
        handleRequestCloseFunc
        /* Function that will be run when the modal is requested
     to be closed (either by clicking on overlay or pressing ESC).
     Note: It is not called if isOpen is changed by other means. */
      }
      closeTimeoutMS={
        0
        /* Number indicating the milliseconds to wait before closing
     the modal. */
      }
      //   style={
      //     // { overlay: {}, content: {} }
      //     customStyles
      //     /* Object indicating styles to be used for the modal.
      //  It has two keys, `overlay` and `content`.
      //  See the `Styles` section for more details. */
      //   }
      contentLabel={
        "Example Modal"
        /* String indicating how the content container should be announced
     to screenreaders */
      }
      portalClassName={
        styles.modalPortal
        /* String className to be applied to the portal.
     See the `Styles` section for more details. */
      }
      overlayClassName={
        styles.modalOverlay
        /* String className to be applied to the overlay.
     See the `Styles` section for more details. */
      }
      id={
        "some-id"
        /* String id to be applied to the content div. */
      }
      className={
        styles.modalContent
        /* String className to be applied to the modal content.
     See the `Styles` section for more details. */
      }
      bodyOpenClassName={
        styles.bodyModalOpen
        /* String className to be applied to the document.body 
     (must be a constant string).
     This attribute when set as `null` doesn't add any class 
     to document.body.
     See the `Styles` section for more details. */
      }
      htmlOpenClassName={
        "doc-modal-open"
        /* String className to be applied to the document.html
     (must be a constant string).
     This attribute is `null` by default.
     See the `Styles` section for more details. */
      }
      ariaHideApp={
        true
        /* Boolean indicating if the appElement should be hidden */
      }
      shouldFocusAfterRender={
        true
        /* Boolean indicating if the modal should be focused after render. */
      }
      shouldCloseOnOverlayClick={
        true
        /* Boolean indicating if the overlay should close the modal */
      }
      shouldCloseOnEsc={
        true
        /* Boolean indicating if pressing the esc key should close the modal
     Note: By disabling the esc key from closing the modal
     you may introduce an accessibility issue. */
      }
      shouldReturnFocusAfterClose={
        true
        /* Boolean indicating if the modal should restore focus to the element
     that had focus prior to its display. */
      }
      role={
        "dialog"
        /* String indicating the role of the modal, allowing the 'dialog' role
     to be applied if desired.
     This attribute is `dialog` by default. */
      }
      parentSelector={
        getParentNode
        // () => document.body
        /* Function that will be called to get the parent element
       that the modal will be attached to. */
      }
      aria={
        {
          labelledby: "heading",
          describedby: "full_description",
        }
        /* Additional aria attributes (optional). */
      }
      //   data={
      //     { background: "green" }
      //     /* Additional data attributes (optional). */
      //   }
      // overlayRef={
      //   setOverlayRef
      //   /* Overlay ref callback. */
      // }
      // contentRef={
      //   setContentRef
      //   /* Content ref callback. */
      // }
    >
      <div className={styles.modalHead}>
        <h3 className={styles.title}>{title}</h3>
        <button onClick={closeModal}>
          <MdClose />
        </button>
      </div>
      <div className={styles.modalBody}>{children}</div>
    </ReactModal>
  )
}

ModalWindow.defaultProps = {
  title: "",
}

ModalWindow.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default ModalWindow
