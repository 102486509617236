import { Link } from "gatsby"
import React, { useState } from "react"
import * as ReactIcon from "react-icons/md"
import auth from "../../../services/auth"
import constants from "../../../services/constants_fhaa"
import util from "../../../services/util"
import ConfirmNavLink from "../../common/confirm-nav-link/confirm-nav-link"
import styles from "./menu.module.scss"


function Menu() {
  const [isToggleOn, setIsToggleOn] = useState(false)
  const [mediaOn, setMediaOn] = useState(false)
  const [moderateOn, setModerateOn] = useState(false)
  const [featuredOn, setFeaturedOn] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const handleClick = () => {
    setIsToggleOn(!isToggleOn)
  }

  const handleMediaClick = () => {
    setMediaOn(!mediaOn)
  }  

  const handleModerateClick = () => {
    setModerateOn(!moderateOn)
  }    

  const handleFeaturedClick = () => {
    setFeaturedOn(!featuredOn)
  }  

  const getUserName = () => {
    let user = auth.getAuth().user
    if (user && user.username) {
      return user.username
    } else {
      return ""
    }
  }

  const genAvatar = () => {
    return getUserName()
      .toUpperCase()
      .substring(0, 1)
  }

  return (
    <div id="menu" className={styles.wrapper}>
      {auth.isLoggedIn() ? (
        <>
          <div
            id="menuUserInfo"
            className={styles.userInfo}
            onClick={handleClick}
          >
            <div className={styles.userAvatar}>{genAvatar()}</div>
            {!util.isMobile() && <div className={styles.userName}>{getUserName()}</div>}
            <div className={styles.dropdownIcon}>
              {" "}
              {React.createElement(ReactIcon["MdExpandMore"])}
            </div>
          </div>
          <ul
            className={
              styles.menuList + (isToggleOn ? " " + styles.openMenu : "")
            }
          >
            <li>
              <ConfirmNavLink to={"/"}>Home</ConfirmNavLink>
            </li>
            <li>
              <div
                id="menuUserInfo"
                className={styles.subHeader}
                onClick={handleMediaClick}
              >
                <div className={styles.subTitle}>Media</div>
                <div className={styles.dropdownIcon}>
                  {" "}
                  {React.createElement(ReactIcon["MdExpandMore"])}
                </div>                
              </div>
              <ul
                className={
                  styles.menuList + (mediaOn ? styles.openMenu : "")
                }
              >
                <li onClick={handleClick}>
                  <ConfirmNavLink
                    to={constants.uploadPath}
                    onNavConfirmed={handleClick}
                  >
                    Upload Media
                  </ConfirmNavLink>
                </li>
                <li>
                  <ConfirmNavLink to={"/view-my-media"}>
                    View My Media
                  </ConfirmNavLink>
                </li>
                <li>
                  <ConfirmNavLink to={"/create-story"}>Create Story</ConfirmNavLink>
                </li>             
              </ul>              
            </li>
            {auth.isModerator() || auth.isBranchAdmin() ? (
            <li>
              <div
                id="menuUserInfo"
                className={styles.subHeader}
                onClick={handleModerateClick}
              >
                <div className={styles.subTitle}>Moderate</div>
                <div className={styles.dropdownIcon}>
                  {" "}
                  {React.createElement(ReactIcon["MdExpandMore"])}
                </div>                
              </div>
              <ul
                className={
                  styles.menuList + (moderateOn ? styles.openMenu : "")
                }
              >
                <li>
                  <ConfirmNavLink to={"/manage-companies"}>
                    Accounts
                  </ConfirmNavLink>
                </li>
                <li>
                  <ConfirmNavLink to={"/keywords"}>
                    Keywords
                  </ConfirmNavLink>
                </li>
                <li id="moderateMediaLink">
                  <ConfirmNavLink to={"/moderate-media"}>
                    Media
                  </ConfirmNavLink>
                </li>
                <li id="duplicateMediaLink">
                  <ConfirmNavLink to={"/duplicate-media"}>
                    Duplicates
                  </ConfirmNavLink>
                </li>           
              </ul>              
            </li>
            ) : (
              ""
            )}  
            {auth.isBranchAdmin() ? (
            <li>
              <div
                id="menuUserInfo"
                className={styles.subHeader}
                onClick={handleFeaturedClick}
              >
                <div className={styles.subTitle}>Featured</div>
                <div className={styles.dropdownIcon}>
                  {" "}
                  {React.createElement(ReactIcon["MdExpandMore"])}
                </div>                
              </div>
              <ul
                className={
                  styles.menuList + (featuredOn ? styles.openMenu : "")
                }
              >
                <li>
                  <ConfirmNavLink to={"/featured-photos"}>
                    Photos
                  </ConfirmNavLink>
                </li>
                <li>
                  <ConfirmNavLink to={"/featured-stories"}>
                    Stories
                  </ConfirmNavLink>
                </li>       
              </ul>              
            </li>
            ) : (
              ""
            )}                 
            <li>
              <ConfirmNavLink to={"/app/login"} doLogout={true}>
                Logout
              </ConfirmNavLink>
            </li>
          </ul>
        </>
      ) : (
        <Link className={styles.wrapper} to="/app/login">
          Login
        </Link>
      )}
    </div>
  )
}

Menu.prototypes = {}

export default Menu
